<template>
  <div class="ag-cell-label-container" role="presentation">
    <span
      data-ref="eMenu"
      class="ag-header-icon ag-header-cell-menu-button"
      aria-hidden="true"
    >
    </span>
    <span
      data-ref="eFilterButton"
      class="ag-header-icon ag-header-cell-filter-button"
      aria-hidden="true"
    ></span>
    <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
      <span data-ref="eText" class="ag-header-cell-text">
        <VDropdown
          :triggers="['click']"
          theme="tasq-dropdown"
          :distance="5"
          :skidding="-30"
        >
          <p class="flex flex-row gap-x-3 justify-end items-center">



            <span class="">
              <mdicon
                name="plus-circle"
                class=" ml-5 float-right "

                :size="20"
              />
            </span>

          </p>

          <template #popper>
            <!-- {{ latestLastAction}} -->

           <div class="w-32 h-16">
            <div
              v-for="(option, index) in ['Team', 'Assignee']"
              :key="index"
              class="cursor-pointer flex flex-row items-center text-tasqNeutral50 overflow-hidden py-0 pr-2.5"
              @click.stop
              @click="addValTShortList(option)"
            >
              <div class="flex items-center">
                <div
                  style="height: 12px; width: 12px;"
                  class="relative ml-1  float-left rounded border border-white012"
                >
                  <div
                    v-if="false"
                    class="absolute bg-green023 rounded-sm ml-0.5 mt-0.5 w-3 h-3"
                  />
                </div>

                <p
                  class="float-left cursor-pointer text-xs font-normal rounded py-1.5 px-2.5"
                >
                  {{ option }}
                </p>
              </div>
            </div>
           </div>

            <!-- <div class="flex flex-col gap-y-3 p-1 px-1 h-72"></div> -->
          </template>
        </VDropdown>
      </span>
      <span
        data-ref="eFilter"
        class="ag-header-icon ag-header-label-icon ag-filter-icon"
        aria-hidden="true"
      ></span>
      <span
        data-ref="eSortOrder"
        class="ag-header-icon ag-header-label-icon ag-sort-order"
        aria-hidden="true"
      ></span>
      <span
        data-ref="eSortAsc"
        class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon"
        aria-hidden="true"
      ></span>
      <span
        data-ref="eSortDesc"
        class="ag-header-icon ag-header-label-icon ag-sort-descending-icon"
        aria-hidden="true"
      ></span>
      <span
        data-ref="eSortNone"
        class="ag-header-icon ag-header-label-icon ag-sort-none-icon"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ascSort: null,
      descSort: null,
      noSort: null,
      columnName: '',
      menuRight: 0,
      columnType: '',
      menuTop: '315px',
      showColumnMenu: false,
    };
  },
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },
  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    didSelectDropdown(event) {
      this.params.context.componentParent.didSelectDropdown();
      this.showColumnMenu = false;
    },
    closeFilterDialog() {
      this.showColumnMenu = false;
    },
    addAssigneColumn() {
      this.params.context.componentParent.addAssigneColumn();
      this.showColumnMenu = false;
    },
    addDefermentColumn() {
      this.params.context.componentParent.addDefermentColumn();
      this.showColumnMenu = false;
    },
    showMenu() {
      const right = document
        .getElementById('ag-grid-id')
        .getBoundingClientRect();
      this.menuRight = `${right.right + 50}px`;
      if (right.right > 1000) {
        this.menuRight = `${0}px`;
      }
      this.menuTop = `${right.top + 150}px`;
      this.showColumnMenu = !this.showColumnMenu;
    },
    onSortChanged() {
      this.ascSort = this.descSort = this.noSort = 'inactive';
      if (this.params.column.isSortAscending()) {
        this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
        this.descSort = 'active';
      } else {
        this.noSort = 'active';
      }
    },
    add(text) {
      this.params.context.componentParent.methodFromParent(text, text);
      this.columnName = '';
      this.columnType = '';
    },
    onSortRequested(order, event) {
      this.params.setSort(order, event.shiftKey);
    },
  },
};
</script>

<style scoped>
.customHeaderLabel {
  cursor: pointer;
}
.customSortDownLabel,
.customSortUpLabel,
.customSortRemoveLabel {
  cursor: pointer;
}
</style>
